<!-- =========================================================================================
    File Name: Calendar.vue
    Description: Calendar App
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="timeOffDashboard">
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
          
      <div>
        <div class="flex justifyBetween items-center">
          <div class="pageTitle">연간 휴가 현황</div>
          <div class="">조회 기간: {{dateRange[0].format('YYYY년 MM월 DD일')}} ~ {{dateRange[1].format('YYYY년 MM월 DD일')}}</div>
        </div>
        <table class="infoTable">
          <tr>
            <th style="width: 100px;">연도</th>
            <th>성명</th>
            <th>부서</th>
            <th>직위</th>
            <th>발생연차</th>
            <th>추가</th>
            <th>공제</th>
            <th>총연차</th>
            <th>총사용</th>
            <th>잔여</th>
            <th>연차 외 휴가 사용</th>
          </tr>
          <tr>
            <td>
              <v-select style="width: 100px;" placeholder="연도" 
                v-model="currentYear" @input="changeYear"
                :options="yearSelect"/>
            </td>
            <td>
              <v-select style="width: 100px;" placeholder="기자" label="name" v-model="writer_s"
                :options="writerOption" @input="changeReporter" :clearable="false" :disabled="$store.state.AppActiveUser.level < 10"/>
            </td>
            <td>
              {{departmentOption.find(e => e.value == writer_s.department) ? 
                departmentOption.find(e => e.value == writer_s.department).text :
                ''
              }}
            </td>
            <td>
              {{levelOption.find(e => e.value == writer_s.level) ? 
                levelOption.find(e => e.value == writer_s.level).text :
                ''
              }}
            </td>
            <td>{{userTimeOff.default}}</td>
            <td>{{userTimeOff.added}}</td>
            <td>{{userTimeOff.removed}}</td>
            <td>{{userTimeOff.total}}</td>
            <td>{{userTimeOff.used}}</td>
            <td>{{userTimeOff.total - userTimeOff.used}}</td>
            <td>{{userTimeOff.extra}}</td>
          </tr>
        </table>
        <div class="flex" style="margin-top: 30px;">
          <apexchart style="width: 700px; height: 400px;" type="heatmap" :options="chartOptions" :series="series"></apexchart>
          <div style="padding-top: 36px;">
            <div class="monthlyTotal">합계</div>
            <div class="monthlyTotal" v-for="(listItem, index) in monthlyUsed" :key="index">
              {{listItem}}
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss">
#timeOffDashboard{
  .pageTitle{
    font-size: 30px;
    line-height: 1.5;
    color: brown;
  }
  .monthlyTotal{
    height: 27px;
    font-size: 12px;
    text-align: center;
  }
  .infoTable{
    text-align: center;
    border-collapse: collapse;
    border-spacing: 1;
  }
}
</style>


<script>
import moment from 'moment';
import { setTimeout } from 'timers';

import FullCalendar from '@fullcalendar/vue';
import koLocale from '@fullcalendar/core/locales/ko';

export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 400,
          height: 300,
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#008FFB"],
        // title: {
        //   text: 'HeatMap Chart (Single color)'
        // },
        xaxis: {
          type: 'category',
          tickAmount: 30,
          categories: this.makeDays(),
          position: 'top',
          // labels:{
          //   hideOverlappingLabels: false,
          // }
        },
        tooltip:{
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            return `<div class="arrow_box">
                <span>
                  ${w.globals.seriesNames[seriesIndex]} ${w.globals.labels[dataPointIndex]}일
                  ${series[seriesIndex][dataPointIndex] == 0 ? '' : 
                    series[seriesIndex][dataPointIndex] == 0.5 ? '' : 
                    series[seriesIndex][dataPointIndex] == 2 ? '대체휴가' : 
                    series[seriesIndex][dataPointIndex] == 5 ? '반차' :
                    series[seriesIndex][dataPointIndex] == 10 ? '연차' :
                    series[seriesIndex][dataPointIndex] == -100 ? '주말' :
                    series[seriesIndex][dataPointIndex] == -110 ? '공휴일' :
                    series[seriesIndex][dataPointIndex]
                  }
                </span>
              </div>`
          }
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                {
                  from: -110,
                  to: -110,
                  color: '#FF5555',
                  name: '공휴일',
                },
                {
                  from: -100,
                  to: -100,
                  color: '#FF7777',
                  name: '주말',
                },
                {
                  from: 0,
                  to: 0,
                  color: '#FFFFFF',
                  name: ' ',
                },
                {
                  from: 0.5,
                  to: 0.5,
                  color: '#EEEEFF',
                  name: '평일',
                },
                {
                  from: 2,
                  to: 2,
                  color: '#CCCCFF',
                  name: '대체휴일',
                },
                {
                  from: 5,
                  to: 5,
                  color: '#8888FF',
                  name: '반차',
                },
                {
                  from: 10,
                  to: 10,
                  color: '#5443FF',
                  name: '연차',
                },
              ]
            }
          }
        }
        // grid:{
        //   show: true,
        //   borderColor: '#90A4AE',
        // }
      },
      currentMonth: '',
      currentYear: '',
      holydayData: [],
      monthlyUsed: [],
      weekdayUsed: [],
      dateRange: [moment(), moment().add(1, 'y').add(-1, 'd')],
      writer_s: {},
      typeSelect: [
        '연차',
        '월차',
        '반차',
        '특별휴가',
        '대체휴가',
      ],
      yearSelect: [],
      userTimeOff:{
        used: 0,
        remain: 0,
        default: 0,
        total: 0,
        added: 0,
        removed: 0,
        extra: 0,
      },
      locale: koLocale,
    }
  },
  components: {
      // 'full-calendar': require('vue-fullcalendar'),
      FullCalendar,
  },
  beforeRouteLeave (to, from, next) {
      // this.activePromptAddEvent = false;
      // this.activePromptEditEvent = false;
      setTimeout(() => { next(); }, 100);
  },
  computed: {
    departmentOption(){
      return this.$store.state.departmentOption
    },
    levelOption(){
      return this.$store.state.levelOption
    },
  },
  methods: {
    moment(data){
      return moment(data);
    },
    makeDays(){
      let data = [];
      for(let i=1; i< 32; i++){
        data.push(i);
      }
      return data;
    },
    makeYears(){
      let result = [];
      const thisYear = moment().year();
      console.log('year: ', thisYear);
      for(let i = thisYear; i > 2018; i--){
        result.push(i);
      }
      return result;
    },
    makeHeatmapData(startDate2, timeoffList){
      this.monthlyUsed = [];
      this.userTimeOff.extra = 0;
      let result = [];
      
      let startDate = moment(startDate2);
      console.log("start date: ", startDate, timeoffList);
      let lastDate = startDate.clone().add(1, 'y');
      let tmpDate = startDate.clone();
      // let tmpMonth = tmpMonth.add(11, 'month');
      let monthData = { name: tmpDate.format('YYYY년 MM월'), data: [] };
      let monthly = 0;
      for(let i = 1; i < tmpDate.date(); i++){
        monthData.data.push({x: `${i}`, y: 0})
      }
      while(lastDate > tmpDate){
        if(tmpDate.date() == 1){
          result.push(monthData);
          this.monthlyUsed.push(monthly);
          monthly = 0;
          monthData = { name: tmpDate.format('YYYY년 MM월'), data: [] };
        }
        let y = 0;
        let tmpIdx = timeoffList.findIndex(e => e.factor >= 0 && e.state > -1 && tmpDate >= moment(e.start) && tmpDate <= moment(e.end));
        if(tmpIdx > -1){
          y = timeoffList[tmpIdx].factor || 0.2;
        }else{
          y = 0.05;
        }
        if(this.holydayData.find(e => e && e.locdate == tmpDate.format('YYYYMMDD'))){
          y = -11;
        }else if(tmpDate.day() == 0 || tmpDate.day() == 6){
          y = -10;
        }
        if(y > 0.4){
          monthly += y;
        }else if(y == 0.2){
          this.userTimeOff.extra++;
        }
        monthData.data.push({x: `${tmpDate.date()}`, y: y * 10})
        tmpDate.add(1, 'day');
      }
      result.push(monthData);
      this.monthlyUsed.push(monthly);
      this.userTimeOff.used = this.monthlyUsed.reduce((a, b) => a + b, 0);
      result.sort((a,b) => {
        if(a.name > b.name){
          return -1;
        }else{
          return 1;
        }
      })
      return result;
    },
    async changeYear(e){
      try {
        let response = await this.$http.post('/api/board/getHolydayList', {data: e});
        this.holydayData = response.data;
      } catch (error) {
        this.httpErrorFunction(error);
      }
      let newDate = moment(this.writer_s.resetDate).year(e).format('YYYY-MM-DD');
      this.getUserTimeOff(this.currentMonth, newDate);
    },
    changeReporter(e){
      this.writer_s = e;
      let newDate = moment(this.writer_s.resetDate).year(this.currentYear).format('YYYY-MM-DD');
      this.getUserTimeOff(this.currentMonth, newDate);
    },
    async getUserTimeOff(currentMonth, entryDate){
      this.dateRange = [moment(entryDate), moment(entryDate).add(1, 'y').add(-1, 'd')];
      this.userTimeOff.remain = this.writer_s.max_timeoff;
      if(!this.userTimeOff.remain){
        this.userTimeOff.remain = 15;
      }
      let contYear = moment(entryDate).diff(moment(this.writer_s.entry_date), 'year');
      if(contYear < 0){
        this.userTimeOff.default = 0;
      }else if(contYear == 0){
        this.userTimeOff.default = moment(entryDate).diff(moment(this.writer_s.entry_date), 'month');
      }else{
        this.userTimeOff.default = this.userTimeOff.remain + parseInt((contYear - 1) / 2);
      }
      try {
        let response = await this.$http.post('/api/board/getUserTimeOff', {currentMonth, entryDate, writer_id: this.writer_s.idx });
        this.userTimeOff.used = 0;
        this.userTimeOff.total = this.userTimeOff.default;
        this.series = this.makeHeatmapData(entryDate, response.data);
        console.log('make data: ', this.series)
      } catch (error) {
          this.httpErrorFunction(error);
      }
    },
    httpErrorFunction(error){
      let errorText = error.toString();
      if(errorText.includes('401')){
        alert('로그인 해주세요!')
        this.$router.push({path: '/pages/login?to='+ this.$router.history.current.path, query: this.$router.history.current.query})
      }
      this.$vs.notify({
        title:'Error',
        text: error,
        color:'danger',
        iconPack: 'feather',
        icon:'icon-alert-circle'})
      this.$vs.loading.close(); 
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    async created(){
      this.yearSelect = this.makeYears();
      // const thisIns = this;
      this.currentMonth = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2);
      try {
        let response = await this.$http.post('/api/board/getHolydayList', {data: moment().year()});
        this.holydayData = response.data;
        // console.log('response:', response)

      } catch (error) {
        this.httpErrorFunction(error);
      }
      if(!this.$store.state.writerAll){
        try {
          let response = await this.$http.post('/api/getVarList/writer', {});
          this.writerOption = response.data;
          this.writerOption = this.writerOption.filter(e => moment(e.entry_date) > moment('2000-01-01'));
          for(let i in this.writerOption){
            const resetDate = moment(this.writerOption[i].entry_date).year(moment().format('YYYY'))
            if(moment() > resetDate){
              this.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
            }else{
              this.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
            }
            this.writerOption[i].entry_date = moment(this.writerOption[i].entry_date).format('YYYY-MM-DD')
            // console.log(this.writerOption[i].entry_date)
          }
          this.$store.dispatch('SetWriterAll', {writerAll: this.writerOption});
          this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
          this.getUserTimeOff(this.currentMonth, this.writer_s.resetDate);
        } catch (error) {
          this.httpErrorFunction(error);
        }
      }else{
        this.writerOption = this.$store.state.writerAll;
        this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
        this.getUserTimeOff(this.currentMonth, this.writer_s.resetDate);
      }
      this.currentYear = moment(this.writer_s.resetDate).year();
      console.log(this.writer_s.resetDate);
    }
  },
  created(){
    this.created();
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/calendar.scss";
.calendar-event-dialog{
  .vs__search, .vs__search:focus{
    width: 0;
  }
  .vs__dropdown-menu{
    height: 220px;
  }
  .mx-input:disabled, .mx-input.disabled{
    color: #555;
  }
}
.mx-datepicker-popup{
  z-index: 100000;
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
#calendar-app{
  .fc-sat{
    background-color: rgba(180, 180, 255, 0.3);
  }
  .fc-sun{
    background-color: rgba(255, 180, 180, 0.3);
  }
  .gcal-event{
    pointer-events: none;
    display: none;
  }
}
</style> 